
import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  ref,
  h,
  watch,
  onMounted
} from "vue";
import TrainingSituation from "./TrainingSituation.vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import { departmentTree } from '@/api/system'
import { DurationStatisticsControllerPage } from '@/api/api'
import { DurationStatisticsControllerDownload } from '@/api/downloadApi'
import {blob} from '@/utils/blob'
import { message } from 'ant-design-vue';


const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "部门",
    dataIndex: "department",
  },
  {
    title: "考生姓名",
    dataIndex: "name",
  },
  {
    title: "身份证号",
    dataIndex: "identification",
  },
  {
    title: "培训次数（次）",
    dataIndex: "num",
  },
  {
    title: "累计培训学时",
    dataIndex: "duration",
  },
  {
    title: "培训合格率",
    dataIndex: "completionRate",
  },
  {
    title: "累计培训有效学时",
    dataIndex: "validDuration",
  }, 
  {
    title: "操作",
    dataIndex: "operation",
    width:200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    formSearch,
    TrainingSituation
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 8 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "考生姓名：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "name", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "时间查询",
          },
          comConfig: {
            tag: "input",
            key: "Slots",
            props: {
              placeholder: "请输入",
            },
          },
        },
        // {
        //   rowProps: {},
        //   colProps: {
        //     span: 8,
        //   },
        //   formItemProps: {
        //     label: "是否合格：",
        //   },
        //   comConfig: {
        //     tag: "select",
        //     key: "pass",
        //     valueKey: "id",
        //     labelKey: "name",
        //     getDataApi: () => {
        //       return new Promise((reslove) => {
        //           reslove([
        //             {
        //               name: "合格",
        //               id: true,
        //             },
        //             {
        //               name: "不合格",
        //               id: false,
        //             },
        //           ]);
        //         })
        //     },
        //     props: {
        //       placeholder: "请输入",
        //     },
        //   },
        // },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          departmentId:departmentId.value,
          createdTime:createdTime.value,
          ...formSearch.value.getQuery(),
        }
        DurationStatisticsControllerPage({ ...params_api.value }).then(res => {
          reslove(res)
        })
      });
    };
    const reset= () => {
      createdTime.value=[]
    };
    function handleDownload() {
      DurationStatisticsControllerDownload({departmentId:departmentId.value,...formSearch.value.getQuery(),createdTime:createdTime.value,"currentPage": 1, "pageSize": 100000, }).then(res => {
        blob(res)
      })
    }
    function downloadSelectApi() {
      if(data.selectedRowKeys.length>0){
        console.log(data.selectedRowKeys);
        message.warning('当前数据为统计数据，无法单独导出，请点击全部导出');
      }else{
        message.warning('请先选择需要下载的内容');
      }
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => { };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleSearch = (formState: any) => {
    
      table.value.refresh();
    };
    const selectedKeys = ref<string[]>([]);
    const departmentId = ref()
    watch(selectedKeys, () => {
      departmentId.value = selectedKeys.value
      handleSearch({})
    });
    const value = ref<string>("");

    const onSearch = () => {
      console.log("value", value.value);
    };
    const treeData = ref([]);
    departmentTree({}).then(res => {
      treeData.value = res.data
    })
    const replaceFields = {
      children: 'children',
      key: 'departmentId',
      title: 'departmentName',
    };
    const createdTime= ref([])
    const TrainingSituation=ref()
    const TrainingSituationClick=(value: any)=> {
        TrainingSituation.value.showModal("安全培训教育信息 ", value,);
      }
    return {
      downloadSelectApi,
      TrainingSituationClick,
      reset,
      TrainingSituation,
      createdTime,
      handleDownload,
      replaceFields,
      value,
      onSearch,
      treeData,
      selectedKeys,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleSearch,
      updatedTime,
    };
  },
});
