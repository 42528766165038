<template>
  <div>
    <a-modal width="1300px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" footer @cancel="cancel">
      <a-spin :spinning="spinning">
        <div style="margin: 10px 0">
          <a-button type="default" @click="download" :disabled="sourceData.length == 0">
            <VerticalAlignBottomOutlined />
            导出表格
          </a-button>
        </div>
        <div>
          规定培训有效学时：<span>{{DataInfo.requireDuration}}</span>  |  
          累计培训有效学时：<span>{{DataInfo.validDuration}}</span>  |  
          <span style="color: #60A2DE;">尚需完成有效培训学时：{{DataInfo.needCreditHour}}</span>
        </div>
        <a-table style="margin-top: 10px; height: 500px; overflow: auto" :columns="columns" :data-source="sourceData"
          :pagination="true" :row-selection="{
            selectedRowKeys: trainingPlanUserIds,
            onChange: onSelectChange,
          }" row-key="trainingPlanUserId">
          <!-- 提交答题时间 -->
          <template #submitTime="{ text, record }">
            <span v-if="record.trainingPlan.type===2">{{text}}</span>
          </template>
          <!-- 考试成绩 -->
          <template #score1="{ text, record }">
            <span style="cursor: pointer; color: blue" @click="downloadTest(record, 0)">{{ text }}</span>
          </template>
          <!-- 考试结果 -->
          <template #TrainingResults="{ text, record }">
            <div v-if="record.trainingPlan.type === 2">
              <span :style="text === 1  ? 'color:#21C28E' : 'color:red'">
                {{ text === 1 ?'成绩合格':(text === 2 ?'成绩不合格':'未完成')}}
              </span>
            </div>
            <div v-else>
              <span :style="record.status === 1  ? 'color:#21C28E' : 'color:red'">
                {{ record.status === 1 ?'成绩合格':(record.status === 2 ?'成绩不合格':'未完成')}}
              </span>
            </div>
          </template>
          <!-- 补考成绩 -->
          <template #score2="{ text, record }">
            <span style="cursor: pointer; color: blue" @click="downloadTest(record, 1)">{{ text }}</span>
          </template>
          <!-- 补考结果 -->
          <template #fillResults="{ text, record }">
            <span v-if="record.score2 !== null" :style="text === 1 ? 'color:#21C28E' : 'color:red'">
              {{ text === 1 ?'成绩合格':(text === 2 ?'成绩不合格':'未完成')}}</span>
          </template>

        </a-table>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
  import { defineComponent } from "vue";
  import { TrainingPlanToUserControllerPage } from "@/api/api";
  import { genPdf } from "@/api/api";
  import { durationStatisticsInfodownloadDownload } from "@/api/downloadApi";
  import { AppTrainingPlanToAchievementController } from "@/api/stipulateTime";
  import { blob } from "@/utils/blob";
  const columns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "index",
      customRender: (row) => row.index + 1,
    },
    {
      title: "培训名称",
      dataIndex: "trainingPlan.title",
    },
    {
      title: "部门",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "姓名",
      dataIndex: "name",
    },
    {
      title: "身份证号",
      dataIndex: "identification",
    },
    {
      title: "培训学时",
      dataIndex: "trainingPlan.creditHours",
    },
    {
      title: "提交答题时间",
      dataIndex: "submitTime",
      slots: { customRender: "submitTime" },
    },
    {
      title: "考试成绩",
      dataIndex: "score1",
      slots: { customRender: "score1" },
    },
    {
      title: "考试结果",
      dataIndex: "status1",
      slots: { customRender: "TrainingResults" },
    },
    {
      title: "补考成绩",
      dataIndex: "score2",
      slots: { customRender: "score2" },
    },
    {
      title: "补考结果",
      dataIndex: "status2",
      slots: { customRender: "fillResults" },
    },
  ];
  export default defineComponent({
    name: "index",
    props: {
      FormData: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        DataInfo:{},
        spinning: true,
        trainingPlanUserIds: [],
        record: {},
        categoryIds: [],
        options: [
          {
            value: "2",
            label: "成绩不合格",
          },
          {
            value: "1",
            label: "成绩合格",
          },
          {
            value: "0",
            label: "未完成",
          },
        ],
        formState: {
          department: [],
          status: "",
          name: "",
        },
        createdTime: [],
        rules: {},
        sourceData: [],
        columns,
        visible: false,
        confirmLoading: false,
        Title: "",
        node: "",
      };
    },
    created() { },
    methods: {
      download() {
        durationStatisticsInfodownloadDownload({
          userId: this.record.userId,
        }).then((res) => {
          blob(res);
        });
      },
      downloadTest(record, val) {
        const params = {
          examinationPlanId: record.examinationPaper
            ? record.examinationPaper
            : 0,
          trainingPlanId: record.trainingPlanId,
          examinationPaperId: record.examinationPaperId
            ? record.examinationPaperId
            : 0,
          retestNum: val,
          source: 3,
          userId: this.record.userId,
        };
        genPdf(params).then((res) => {
          window.open(res.data);
        });
      },
      onSelectChange(selectedRowKeys) {
        this.trainingPlanUserIds = selectedRowKeys;
      },
      cancel() {
        this.visible = false;
        this.reset();
      },
      reset() {
        this.formState = {
          department: [],
          status: "",
          name: "",
        };
      },
      query() {
        this.api();
      },
      api() {
        this.trainingPlanUserIds = [];
        this.sourceData = [];
        const params = {
          currentPage: 1,
          pageSize: 100000,
          userId: this.record.userId,
        };
        TrainingPlanToUserControllerPage(params).then((res) => {
          this.spinning = false;
          this.sourceData = res.data
        });
      },
      showModal(val, record, createdTime) {
        this.Title = val;
        this.visible = true;
        this.record = record;
        this.spinning = true;
        this.api();
        AppTrainingPlanToAchievementController({ userId: this.record.userId }).then(res => {
          this.DataInfo=res.data[0]
        })
      },
    },
  });
</script>
<style lang="less"></style>