import axios from "../axios-config";
import { encrypt } from '@/utils/rsaEncrypt'

//用户管理
 /**
   * 查询部门树
   */
  export function departmentTree() {
    return axios.get("/api/auth/department/tree" );
  }
    /**
 * 分配用户角色
 */
     export function userRole(params: any) {  
      return axios.post("/api/auth/userRole/assign", {...params});
  }
  /**
* 查询用户角色信息详情
*/
export function userId(id: number) {
  return axios.get("/api/auth/user/"+id);
  }
/**
 * 查询用户管理信息
 */
 export function userInfoPage(params: any) {
    return axios.get("/api/auth/userInfo/page", {params:{
      ...params
    }});
  }
 
//角色管理
  /**
 * 新增角色
 */
   export function role_post(params: any) {  
    return axios.post("/api/auth/role", {...params});
}

/**
* 修改角色
*/
export function role_put(params: any) {
return axios.put("/api/auth/role", {...params});
}
/**
 * 删除角色
 */
 export function role_del(params: any) {
  return axios.delete("/api/auth/role",{params:{
    ...params
  }});
}
/**
* 查询角色管理信息
*/
export function rolePage(params: any) {
return axios.get("/api/auth/role/page", {params:{
  ...params
}});
}
/**
* 查询角色管理信息详情
*/
export function roleIdAPI(id: number) {
  return axios.get("/api/auth/role/"+id);
  }
/**
 * 为角色分配菜单
 */
   export function roleAssign_post(params: any) {  
    return axios.post("/api/auth/role/assign", {...params});
}


//菜单管理
/**
 * 新增菜单
 */
    export function menu_post(params: any) {  
        return axios.post("/api/auth/menu", {...params});
    }
    
/**
 * 修改菜单
 */
export function menu_put(params: any) {
    return axios.put("/api/auth/menu", {...params});
  }

/**
 * 删除菜单
 */
 export function menu_del(params: any) {
  return axios.delete("/api/auth/menu",{params:{
    ...params
  }});
}

 /**
   * 查询菜单树
   */
  export function menuTree(params: any) {
    return axios.get("/api/auth/menu/tree", {params:{
      ...params
    }});
  }
  /**
   * 查询菜单管理信息
   */
   export function menuPage(params: any) {
    return axios.get("/api/auth/menu/page", {params:{
      ...params
    }});
  }

  //参训人员专用
   /**
   * 查询参训人员部门树
   */
    export function childrenTree(params: any) {
      return axios.get("/api/auth/department/children", {params:{
        ...params
      }});
    }
     /**
 * 查询部门下所有的人（用于参训人员数据）
 */
  export function userToDepartmentUserDeep(params: any) {  
    return axios.get("/api/auth/userToDepartment/userDeep",  {params:{
      ...params
    }});
}

// 修改密码
export function updatePassword(val: any) {
  const data = {
    password: encrypt(val.password),
    userId:val.userId
  }
  return axios.put("/api/auth/user/updatePassword", data);
}