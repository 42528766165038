import axios from "../axios-config";

//时长统计
export function CreditHourSettingControllerPage(params: any) {
  return axios.get("/api/back/learn/CreditHourSettingController/page", {params:{
    ...params
  }});
}

//时长编辑
export function CreditHourSettingController(params: any) {
  return axios.put("/api/back/learn/CreditHourSettingController", params);
}


/**
 *  查询个人页面培训学习时长
 */
export function AppTrainingPlanToAchievementController(params: any) {
  return axios.get(`/api/back/learn/TrainingPlanToAchievementController/pageStatistic`, {
    params: {
      ...params
    }
  })
}